import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '', redirectTo: 'master', pathMatch: 'full'
  },
  { 
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  { 
    path: 'master',
    loadChildren: () => import('./modules/master/master.module').then(m => m.MasterModule)
  },
  { 
    path: 'domain',
    loadChildren: () => import('./modules/domain/domain.module').then(m => m.DomainModule)
  },
  { 
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
  },
  { 
    path: 'billing',
    loadChildren: () => import('./modules/billing/billing.module').then(m => m.BillingModule)
  },
  { 
    path: 'company',
    loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule)
  },
  { 
    path: 'tenant',
    loadChildren: () => import('./modules/tenant/tenant.module').then(m => m.TenantModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule, TranslateModule]
})
export class AppRoutingModule { }
